.home-container {

  .running-info {
    position: relative;
    margin-bottom: 1rem;
    transform: translateX(100%);
    transition: all 2s 1s ease;

    &.show {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .running-text {
    color: white;
    font-size: .7rem;
    font-weight: normal;

    span {
      margin: auto .2rem;
    }
  }

  .logo {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1.5);
    text-align: center;
    margin: 0 auto;
    opacity: 0;
    transition: all 1s ease;

    &.show {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }

    .macs {
      display: block;
      height: 3.5rem;
      margin: 0 auto;
    }

    .hybrid-gel {
      display: inline-block;
      margin-top: .8rem;
      padding: .2rem .5rem;
      font-family: "ethnocentric";
      font-size: 1rem;
      color: white;
      background: #004479;
      border: 1px solid white;
      border-radius: .5rem;
      -webkit-text-stroke: 1px #004479;
      transition: all .5s ease;
      transform: translateY(1rem);
      opacity: 0;
    }

    .macs-hybrid-gel {
      display: inline-block;
      margin-top: .8rem;
      height: 3.5rem;
      transition: all .5s ease;
      transform: translateY(1rem);
      opacity: 0;
    }
  }

  &.start {
    .logo {
      top: 0;
      transform: translate(-50%, 10%) scale(.8);

      .hybrid-gel,
      .macs-hybrid-gel {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  .banner {
    position: relative;
    margin-top: 20rem;
    height: 0rem;
    background-color: #edf2f6;
    border: 0px solid #edf2f6;
    transition: all 1s 2s ease;

    .pen-container {
      position: absolute;
      bottom: -.2rem;
      left: -100%;
      width: 100%;
      transition: all 2.5s ease;

      .pen-box {
        position: absolute;
        height: 18rem;
        bottom: 2rem;
        left: 5rem;
        opacity: 0;
        transition: left 2.5s ease;
      }

      .pen {
        height: 20rem;
        transform: rotate(25deg) translate(2rem, -1rem);
        transform-origin: bottom center;
      }
    }

    .line {
      position: absolute;
      bottom: .4rem;
      left: -100%;
      width: calc(100% + 3.2rem);
      height: 1px;
      background-color: white;
    }

    .grand-prize {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      padding: .5rem;
      box-sizing: border-box;
      transform: translateX(-100%);
      transition: all 1s .5s ease;

      &.show {
        transform: translateX(0);
      }

      .title {
        font-size: 1.5rem;
        font-weight: 800;
        -webkit-text-stroke: 1px #fff;
      }

      .batch {
        display: inline-block;
        background-color: #004479;
        font-size: .6rem;
        font-weight: 600;
        color: white;
        padding: .2rem .5rem;
        border-radius: 2rem;
      }

      .kijang-innova {
        display: block;
        height: 3.8rem;
      }

      .animation-car {
        height: 5.5rem;
        margin-top: 1rem;
      }

      .sprites-animation {
        margin-top: 1rem;
        background-image: url(../../assets/images/innova/sprites.png);
        background-size: cover;
        background-repeat: no-repeat;
        width: 10rem;
        height: 5.5rem;
        animation: sprite 4s steps(24) infinite;
      }
    }

    .info {
      position: absolute;
      bottom: 0;
      opacity: 0;
      transform: translateX(100%);
      transition: all 2s 1s ease;

      &.show {
        transform: translateX(0);
        opacity: 1;
      }

      .step {
        display: flex;
        align-items: center;
        gap: .3rem;
        padding: .4rem;
        font-size: .7rem;

        .number {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 1.2rem;
          height: 1.2rem;
          background-color: #555;
          color: white;
          font-size: .8rem;
          font-weight: 600;
          border-radius: .4rem;
        }

        span {
          color: #333;
        }
      }
    }

    &.start {
      margin-top: 12rem;
      height: 17rem;
      border: 1px solid white;

      .pen-container {
        left: 100%;

        &.show {
          left: calc(100% - 15rem);
          transition: all 1s ease;

          .pen {
            display: none;
          }

          .pen-box {
            left: 5.5rem;
            opacity: 1;
          }

          .line {
            opacity: 0;
          }
        }
      }
    }
  }

  .authentication {
    margin: 1rem;
    padding: 1rem;
    border-radius: 1rem;
    background-color: white;
    border: 1px solid #ddd;
    border-bottom: 4px solid #ddd;
    opacity: 0;
    transform: translateY(-2rem);
    transition: all .5s ease;
    z-index: 2;

    .field {
      margin-bottom: .8rem;

      .input-wrapper {
        position: relative;

        .loader {
          position: absolute;
          top: 50%;
          right: .5rem;
          height: 1.5rem;
          transform: translateY(-50%);
          z-index: 2;
        }
      }
    }

    &.show {
      opacity: 1;
      transform: translateY(0);
    }

    .label {
      font-size: .8rem;
      font-weight: 500;
      margin-bottom: .2rem;
    }

    .error {
      font-size: .8rem;
      color: #cc0000;
    }

    input {
      width: 100%;
      border-radius: .4rem;
      border: 1px solid #ddd;
      padding: .5rem;
      box-sizing: border-box;
      font-size: 1rem;
      font-weight: 500;
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      border-radius: .4rem;
      border: 1px solid #ddd;
      border-bottom: 3px solid #ccc;
      background-color: #efefef;
      padding: .5rem;
      box-sizing: border-box;
      font-size: 1rem;
      font-weight: 500;
      color: #333;

      &:disabled {
        color: #939393;
      }

      .loader {
        display: inline-block;
        height: 1.5rem;
      }
    }
  }

  .powered {
    margin: 1rem;
    margin-bottom: 5rem;
    text-align: center;
    opacity: 0;
    transform: translateY(2rem);
    transition: all 1s ease;

    &.show {
      opacity: 1;
      transform: translateY(0);
    }

    .button {
      display: inline-block;
      margin: 0 auto;
      cursor: pointer;
    }

    .label {
      font-size: .7rem;
      color: white;
    }

    .logo-loyalid {
      height: 1.5rem;
      margin-top: .2rem;
    }
  }
}

@keyframes sprite {
  from {
    background-position: 0% 0;
  }

  to {
    background-position: 100% 0;
  }
}