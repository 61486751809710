.account-container {
  position: relative;
  padding-top: 9rem;

  .back {
    position: fixed;
    max-width: 28rem;
    width: 100%;
    top: 0;
    box-sizing: border-box;
    margin: 0 auto;
    overflow: hidden;
    z-index: 100;

    .button {
      display: inline-block;
      padding: 1rem;
      border-radius: 100%;
      animation: show-back 1s ease forwards;

      .icon {
        display: block;
        height: 2.5rem;
      }
    }
  }

  .logo {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 10%) scale(.8);
    text-align: center;
    margin: 0 auto;
    transition: all 1s ease;

    .macs {
      display: block;
      height: 3.5rem;
      margin: 0 auto;
    }

    .hybrid-gel {
      display: inline-block;
      margin-top: .8rem;
      padding: .2rem .5rem;
      font-family: "ethnocentric";
      font-size: 1rem;
      color: white;
      background: #004479;
      border: 1px solid white;
      border-radius: .5rem;
      -webkit-text-stroke: 1px #004479;
      transition: all .5s ease;
    }

    .macs-hybrid-gel {
      display: inline-block;
      margin-top: .8rem;
      height: 3.5rem;
      transition: all .5s ease;
    }
  }

  .running-info {
    position: relative;
    margin-bottom: 1rem;
    transform: translateX(100%);
    transition: all 2s 1s ease;

    &.show {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .running-text {
    color: white;
    font-size: .8rem;
    font-weight: normal;

    span {
      margin: auto .2rem;
    }
  }

  .pen {
    display: block;
    margin: 0 auto;
    width: 90%;
    opacity: 0;
    animation: show-pen .5s ease forwards;
  }

  .card {
    display: flex;
    align-items: center;
    margin: 1rem;
    background-color: white;
    padding: .8rem;
    border-radius: 1.7rem;
    opacity: 0;
    animation: show-account-card .5s .3s ease forwards;

    .profile {
      flex: 1;
      min-width: 0;

      >div {
        display: flex;
        align-items: center;
      }

      .icon {
        height: 1.2rem;
        opacity: .8;
      }

      .name {
        font-size: 1rem;
        font-weight: 500;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .skeleton {
        width: 10rem;
        height: 1rem;
        background-color: #eee;
        border-radius: 1rem;
        transform-origin: left center;
        opacity: 0;
        animation: skeleton 1s ease infinite;
      }
    }

    .lucky-number {
      position: relative;
      width: 3.5rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background-color: white;
      border: 1px solid #ddd;
      border-radius: 1rem;
      padding: .4rem;
      cursor: pointer;

      .icon {
        height: 2rem;
      }

      .label {
        font-size: .6rem;
        font-weight: 600;
        line-height: .7rem;
        text-align: center;
      }

      .badge {
        position: absolute;
        top: -.2rem;
        right: -.2rem;
        background-color: #cc0000;
        color: white;
        padding: .2rem .4rem;
        border-radius: 4rem;
        font-size: .6rem;
        font-weight: 600;
        transform: scale(0);
        opacity: 0;
        animation: show-badge .3s ease forwards;
        z-index: 2;
      }
    }

    input {
      width: 100%;
      padding: 0;
      border: none;
      background-color: transparent;
      font-size: 1rem;
      font-weight: 500;
      color: #333 !important;
      pointer-events: none;
    }

  }

  .words {
    display: flex;
    justify-content: center;
    gap: .4rem;
    padding: .2rem;
    box-sizing: border-box;

    .box {
      border-radius: .46rem .46rem .7rem .7rem;
      box-sizing: border-box;
      border: 1px solid #ddd;
      border-bottom-width: 4px;
      transform: scale(.9);
      opacity: .2;
      transition: all 1s ease;

      &.active {
        transform: scale(1) !important;
        opacity: 1;
      }

      .border {
        width: 1.8rem;
        height: 1.8rem;
        padding: .2rem;
        background-color: #eee;
        border: 2px solid white;
        border-radius: .4rem;
      }

      .inner {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        border-radius: .2rem;
        background-color: #004479;
        color: white;
        border: 1px solid #bfbfbf;
        box-sizing: border-box;
        font-family: "ethnocentric";
        // -webkit-text-stroke: 1px #004479;
        font-size: 1.2rem;
      }
    }
  }

  .info {
    margin: 1rem;
    font-size: .8rem;
    text-align: center;
    color: white;
    opacity: 0;
    animation: show-account-card .5s .5s ease forwards;
  }

  .input-code {
    padding: 1rem;
    padding-top: 0;
    opacity: 0;
    animation: show-account-card .5s .8s ease forwards;

    input {
      width: 100%;
      border-radius: .4rem;
      border: 1px solid #ddd;
      padding: .5rem;
      box-sizing: border-box;
      font-size: 1rem;
      font-weight: 500;
      text-align: center;
    }

    button {
      margin-top: .5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      border-radius: .4rem;
      border: 1px solid #ddd;
      border-bottom: 3px solid #ccc;
      background-color: #efefef;
      padding: .5rem;
      box-sizing: border-box;
      font-size: 1rem;
      font-weight: 500;
      color: #333;

      &:disabled {
        color: #939393;
      }

      .loader {
        display: inline-block;
        height: 1.5rem;
      }
    }
  }

  .generate {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    z-index: 1000;
    visibility: hidden;
    overflow: auto;
    transition: all .5s ease;

    .content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      max-width: 28rem;
      padding: 1rem;
      box-sizing: border-box;

      .box {
        position: relative;
        text-align: center;
        background-color: white;
        border-radius: 2rem;
        padding: 1rem;
        opacity: 0;
        transform: scale(1.1);
        transition: all .3s ease;

        .random-word {
          font-family: 'ethnocentric';
          font-size: 8rem;
        }

        .collect {
          display: flex;
          justify-content: center;
          font-size: 1rem;
          margin-bottom: .3rem;

          .word {
            position: relative;
            font-family: 'ethnocentric';
            opacity: .2;
            transition: all .3s ease;

            &.space {
              margin-right: .5rem;
            }

            &.active {
              opacity: 1;
            }
          }
        }

        .info {
          display: flex;
          flex-direction: column;
          gap: .5rem;
          margin: 0;
          border: 1px solid #ddd;
          border-radius: 1rem;
          padding: .5rem;
          font-size: .8rem;
          font-weight: 500;
          color: #333;
          max-height: 0;
          height: 100%;
          overflow: hidden;
          opacity: 0;
          animation: unset;

          &.show {
            opacity: 1;
            max-height: 50vh;
            transition: max-height 1s ease;
          }
        }

        .voucher {
          .voucher-card {
            background-color: white;
            border-radius: 1.5rem;
            text-align: center;
            margin-top: .5rem;
            border: 1px solid #eee;
            box-shadow: 0 1px 3px rgba(0, 0, 0, .1), 0 1px 2px rgba(0, 0, 0, .2);

            .inner {
              position: relative;
              padding: 1rem;
              background-color: #fe5722;
              border-radius: 1.3rem;

              &::after {
                content: "";
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: -1rem;
                width: 2rem;
                height: 2rem;
                background-color: white;
                border-radius: 100%;
                z-index: 2;
              }

              &::before {
                content: "";
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: -1rem;
                width: 2rem;
                height: 2rem;
                background-color: white;
                border-radius: 100%;
                z-index: 2;
              }
            }

            .image {
              height: 3rem;
            }

            .amount {
              font-size: 2.8rem;
              font-weight: 600;
              line-height: 2rem;
              color: white;
              margin-top: .5rem;
            }

            .code {
              margin-top: 1rem;
              color: white;
              padding: .5rem .2rem;
              background-color: #d33300;
              border-radius: .6rem;

              .label {
                font-size: .6rem;
              }

              .number {
                font-size: 1.3rem;
                font-weight: 300;
                line-height: 1.3rem;
              }
            }

            .expired {
              font-size: .8rem;
              font-weight: 500;
              color: #333;
              padding: .5rem .2rem;
            }
          }

          .download-button {
            margin-top: .5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            border-radius: .4rem;
            border: 1px solid #ddd;
            border-bottom: 3px solid #ccc;
            background-color: #efefef;
            padding: .4rem;
            box-sizing: border-box;
            font-size: .8rem;
            font-weight: 500;
            color: #333;

            .icon {
              display: block;
              height: 2.5rem;
            }
          }
        }

        .text {
          font-size: .7rem;
          font-weight: 500;
        }

        .close {
          position: absolute;
          top: -3.5rem;
          right: -1rem;
          transform: scale(0);
          transition: all .3s ease;
          padding: 1rem;

          &.show {
            transform: scale(1);
          }

          .icon {
            height: 2rem;
          }
        }
      }
    }

    &.show {
      visibility: visible;
      background-color: rgba(0, 0, 0, .8);
      transition: all 1s ease;

      .box {
        opacity: 1;
        transform: scale(1);
        transition: all .5s ease;
      }
    }
  }

  .powered {
    margin: 1rem;
    margin-bottom: 5rem;
    text-align: center;
    opacity: 0;
    animation: show-account-card .5s 1s ease forwards;

    .button {
      display: inline-block;
      margin: 0 auto;
      cursor: pointer;
    }

    .label {
      font-size: .7rem;
      color: white;
    }

    .logo-loyalid {
      height: 1.5rem;
      margin-top: .2rem;
    }
  }
}

@keyframes show-back {
  from {
    transform: translateX(-200%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes show-pen {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes show-account-card {
  from {
    opacity: 0;
    transform: translateY(1rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes show-box {
  0% {
    opacity: 1;
    transform: scale(0);
  }

  to {
    opacity: .2;
    transform: scale(.9);
  }
}

@keyframes show-badge {
  from {
    opacity: 0;
    transform: scale(0);
  }

  to {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes skeleton {
  0% {
    opacity: 0;
    transform: scale(.9);
  }

  50% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(.9);
  }
}