.luckydraw-container {
  position: relative;
  padding-top: 10rem;

  .back {
    position: fixed;
    max-width: 28rem;
    width: 100%;
    top: 0;
    box-sizing: border-box;
    margin: 0 auto;
    overflow: hidden;
    z-index: 100;

    .button {
      display: inline-block;
      padding: 1rem;
      border-radius: 100%;
      animation: show-back 1s ease forwards;

      .icon {
        display: block;
        height: 2.5rem;
      }
    }
  }

  .logo {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, 10%) scale(.8);
    text-align: center;
    margin: 0 auto;
    transition: all 1s ease;

    .macs {
      display: block;
      height: 3.5rem;
      margin: 0 auto;
    }

    .hybrid-gel {
      display: inline-block;
      margin-top: .8rem;
      padding: .2rem .5rem;
      font-family: "ethnocentric";
      font-size: 1rem;
      color: white;
      background: #004479;
      border: 1px solid white;
      border-radius: .5rem;
      -webkit-text-stroke: 1px #004479;
      transition: all .5s ease;
    }

    .macs-hybrid-gel {
      display: inline-block;
      margin-top: .8rem;
      height: 3.5rem;
      transition: all .5s ease;
    }
  }

  .title {
    text-align: center;
    font-size: 1rem;
    font-weight: 600;
    color: white;
  }

  .lucky-number {
    display: grid;
    grid-template-columns: calc(50% - .5rem) calc(50% - .5rem);
    grid-gap: 1rem;
    padding: 1rem;
    box-sizing: border-box;
    overflow: unset !important;

    .card {
      position: relative;
      padding: 1rem .5rem;
      background-color: white;
      text-align: center;
      border-radius: .2rem;
      font-size: .9rem;
      font-weight: 600;
      opacity: 0;
      animation: show-card .3s ease forwards;

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -.6rem;
        width: 1rem;
        height: 1.5rem;
        background-color: #0161ba;
        border-radius: 100%;
        z-index: 2;
      }

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -.6rem;
        width: 1rem;
        height: 1.5rem;
        background-color: #0161ba;
        border-radius: 100%;
        z-index: 2;
      }
    }
  }

  .complete {
    margin: 0 auto;
    grid-column: 1/-1;
    width: 5rem;
    height: .2rem;
    border-radius: .2rem;
    background-color: white;
  }

  .loader {
    display: block;
    margin: 0 auto;
    grid-column: 1/-1;
    height: 3rem;
  }
}

@keyframes show-back {
  from {
    transform: translateX(-200%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes show-card {
  from {
    opacity: 0;
    transform: translateY(5rem) scale(.8);
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}