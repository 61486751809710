.alert {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  z-index: 1000;
  visibility: hidden;
  transition: all .5s ease;
  z-index: 999999999;

  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 28rem;
    padding: 1rem;
    box-sizing: border-box;

    .box {
      position: relative;
      text-align: center;
      background-color: white;
      border-radius: 1rem;
      opacity: 0;
      transform: scale(1.1);
      transition: all .3s ease;
      overflow: hidden;

      .text {
        padding: 1.5rem 1rem;
        font-size: .8rem;
        font-weight: 500;
      }
    }

    button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      border: none;
      background: white;
      border-top: 1px solid #ddd;
      padding: .5rem;
      box-sizing: border-box;
      font-size: 1rem;
      font-weight: 500;
      color: #333;

      &:hover {
        background-color: #eee;
      }
    }

    .whatsapp-button {
      padding: 1rem;
      padding-top: 0;

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: .5rem;
        background-color: #259535;
        color: white;
        border: 1px solid #ddd;
        border-radius: .5rem;
        font-size: .8rem;
        font-weight: 500;
      }

      .icon {
        height: 1.5rem;
      }
    }

    .retry-button {
      padding: .2rem 1rem;
      padding-top: 0;

      button {

        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        border-radius: .4rem;
        border: 1px solid #ddd;
        border-bottom: 3px solid #ccc;
        background-color: #efefef;
        padding: .5rem;
        box-sizing: border-box;
        font-size: .9rem;
        font-weight: 500;
        color: #333;

        &:disabled {
          color: #939393;
        }

        .loader {
          display: inline-block;
          height: 1.5rem;
        }
      }
    }
  }

  &.show {
    visibility: visible;
    background-color: rgba(0, 0, 0, .8);
    transition: all 1s ease;

    .box {
      opacity: 1;
      transform: scale(1);
      transition: all .5s ease;
    }
  }
}