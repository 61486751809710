html,
body {
  margin: 0;
  padding: 0;
  background-color: #edf2f6;
}

html {
  &::before {
    content: "";
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    max-width: 28rem;
    width: 100%;
    height: 100%;
    // background: linear-gradient(#0161ba, #008ff2);
    background: #0161ba;
    z-index: -1;
  }
}

* {
  -webkit-tap-highlight-color: transparent;
  font-family: "Poppins";
  -ms-overflow-style: none;
  scrollbar-width: none;
}

::-webkit-scrollbar {
  display: none;
}

@font-face {
  font-family: "ethnocentric";
  src: local("ethnocentric"), url("./assets/fonts/ethnocentric.ttf") format("truetype");
}

.scan-region-highlight {
  display: none;
}

*:focus {
  outline: none;
}

* {
  user-select: none;
  -webkit-user-drag: none;
}

img {
  pointer-events: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}